import {
  Modal,
  Button,
  InputGroup,
  FormControl,
  ModalFooter,
} from 'react-bootstrap';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ModalComponent = ({ text, variant, isLoginFlow }) => {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);

  const navigate = useNavigate();

  const handleClick = async () => {
    let data;
    if (isLoginFlow) {
      const { data: loginData } = await axios.post(
        'https://dually.herokuapp.com/auth/login',
        {
          email,
          password,
        }
      );
      data = loginData;
    }
    if (data.errors.length) {
      return setErrorMsg(data.errors[0].msg);
    }

    localStorage.setItem('token', data.data.token);
    navigate('/articles');
  };

  return (
    <>
      <Button
        onClick={handleModalShow}
        variant={variant}
        size="lg"
        style={{ marginRight: '1rem', padding: '0.5rem 3rem' }}
      >
        {text}
      </Button>
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header>
          <Modal.Title>{text}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text>Email</InputGroup.Text>
            <FormControl
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text>Password</InputGroup.Text>
            <FormControl
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </InputGroup>
          {errorMsg && <dir className="errormessage">{errorMsg}</dir>}
        </Modal.Body>
        <ModalFooter>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClick}>
            {text}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default ModalComponent;
