import { Navbar, NavItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <Navbar>
      <NavItem>
        <Link to="/" className="NavLink">
          Home
        </Link>
      </NavItem>
    </Navbar>
  );
};

export default Header;
