import { Container } from 'react-bootstrap';
import RegisterComponent from './RegisterComponent';
import LoginComponent from './LoginComponent';

const Hero = () => {
  return (
    <div className="herocomponent">
      <Container>
        <div className="headercontainer">
          <h1 className="heading">Dually</h1>
          <h3 className="subheading">Here you can find love</h3>
          <RegisterComponent
            text="Signup"
            variant="primary"
            isSignupFlow={true}
          />
          <LoginComponent text="Login" variant="danger" isLoginFlow={true} />
        </div>
      </Container>
    </div>
  );
};

export default Hero;
